<script>
import LanguageSelector from '@/components/general/LanguageSelector'
export default {
  name: 'AuthTemplate',
  components: { LanguageSelector },
  props: {
    color: { type: String, default: 'primary-medium' },
    logo: { type: String, default: '/assets/images/dark-yellow-logo.png' },
    imageUrl: { type: String, default: '' },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    language: { type: String, default: '' }
  }
}
</script>
<template>
  <div class="auth--container" :class="color">
    <div class="auth-container--wrapper">
      <div class="presentation--container">
        <div class="header--container">
          <img class="logo" :src="logo" />
          <language-selector @language="(language) => $emit('language', language)" :theme="color" :languageProp="language"/>
        </div>
        <div class="image--container">
          <slot name="image"/>
        </div>
        <div :class="color === 'pwfglobal' || color === 'vivae' || color === 'acate' || color === 'decolaentregadores' ?'footer--container-wf-global':'footer--container'">
          <h1>{{ title }} </h1>
          <h5>{{ subtitle }}</h5>
        </div>
      </div>
      <div class="auth--form">
        <slot name="form"/>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.auth--container {
  display: flex;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  width: 100%;
  padding: 40px 40px 0;
  &.empregatransporte {
    .auth-container--wrapper {
      .presentation--container {
        .header--container {
          .logo {
            height: 70px;
          }
        }
      }
    }
  }
  .auth-container--wrapper {
    width: 1250px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .presentation--container {
      justify-content: flex-start;
      width: 50%;
      .image--container {
        height: 330px;
        width: 330px;
        margin-top: 120px;
      }
      .footer--container {
        color: $neutral-white;
        text-align: left;
        h1 {
          font-family: $lato;
          font-size: 72px;
          font-weight: 700;
        }
        h5 {
          font-family: $lato;
          font-size: 24px;
          font-weight: 400;
        }
      }
      .footer--container-wf-global{
          color: #212121;
        text-align: left;
        h1 {
          font-family: $lato;
          font-size: 72px;
          font-weight: 700;
        }
        h5 {
          font-family: $lato;
          font-size: 24px;
          font-weight: 400;
        }
        }

      .header--container {
        display: flex;
        width: 100%;
        .logo {
          height: 40px;
          width: 152px;
          margin-right: 36px;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 30px 0 0;
    .auth-container--wrapper {
      flex-direction: column;
      .auth--form {
        width: 100%;
        margin-left: unset;
        .registration--container {
          margin: 0 auto;
        }
      }
      .presentation--container {
        width: 100%;
        padding: 0 24px 24px;
        position: relative;
        .header--container {
          margin-bottom: 15px;
          justify-content: space-between;
        }
        .image--container {
          position: absolute;
          opacity: 0.5;
          width: 150px;
          height: 150px;
          bottom: -30px;
          right: 15px;
        }
        .footer--container {
          h1 {
            font-size: 24px;
          }
          h5 {
            font-size: 16px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .presentation--container {
      .image--container {
        width: 300px;
        height: 300px;
      }
      .footer--container {
        h1 {
          font-size: 48px;
        }
        h5 {
          font-size: 18px;
        }
      }
    }
    .auth--form {
      margin-left: 30px
    }
  }
}
.primary-medium {
  background-color: $primary-medium;
}
.dark-blue {
  background-color: $primary-darkest;
}

.defaultLogin{
  background-color: #1971F3;
}
.empregosagro {
  background-color: #006C4C;
}
.empregatransporte {
  background-color: #003770;
}
.vivae {
  background-color: #76E100;
}
.portalfenacon {
  background-color: #253E69;
}
.pwfglobal {
  background-color: #F1CF00;
}
.coursera {
  background-color: #0056D2;
}
.acate {
  background-color: #B3C744;
}
.govsp {
  background-color: #191919;
}
.govmg {
  background-color: #3C3C3B;
}
.govmt {
  background-color: #005198;
}
.goves {
  background-color: #32114B;
}
.senacsc {
  background-color: #2554B6;
}
.decolaentregadores {
  background-color: #F6F6F6;
}

.acats {
  background-color: #2D5AA4;
  .auth-container--wrapper {
    .presentation--container {
      .image--container {
        height: 150px;
      }
    }
  }
  @media only screen and (max-width: 768px) {

  }
}
@media only screen and (max-width: 768px) {
  .acats.auth--container .auth-container--wrapper .presentation--container .image--container {
    position: relative;
    opacity: 1;
    margin-top: 20px;
  }
  #app {
    padding-bottom: 0 !important;
  }
  div > #launcher {
    margin-bottom: 10px !important;
  }
}
</style>

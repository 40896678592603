<script>
// import i18n from '@/support/i18n'
import { mapActions } from 'vuex'
export default {
  name: 'LanguageSelector',
  props: {
    color: {
      type: String,
      default: 'dark-blue'
    },
    theme: {
      type: String
    },
    languageProp: {
      type: String
    }
  },
  data: () => ({
    languages: [
      {
        text: 'Português (Brasil)',
        value: 'pt-BR'
      },
      {
        text: 'English',
        value: 'en'
      }
    ],
    selectedLanguage: 'pt-BR'
  }),
  created () {
    this.selectedLanguage = this.languageProp ? this.handleLanguage(this.languageProp) : this.handleLanguage(navigator.language)
    this.setLanguage(this.selectedLanguage)
  },
  computed: {
    language () {
      return this.$store.getters.getLanguage
    }
  },
  watch: {
    language () {
      switch (this.language) {
        case 'pt':
        case 'pt-br':
        case 'pt-BR':
        case 'PT-BR':
          this.selectedLanguage = 'pt-BR'
          break
        case 'EN':
        case 'en':
        case 'en-US':
          this.selectedLanguage = 'en'
          break
      }
    }
  },
  methods: {
    ...mapActions(['setLanguage']),
    changeLanguage (language) {
      this.setLanguage(language)
      this.$emit('language', language)
    },
    handleLanguage (value) {
      switch (value) {
        case 'pt':
        case 'pt-br':
        case 'pt-BR':
        case 'PT-BR':
          return 'pt-BR'
        case 'EN':
        case 'en':
        case 'en-US':
          return 'en'
      }
    }
  }
}
</script>
<template>
  <v-select
    v-model="selectedLanguage"
    class="language-selector--container"
    :class="{'is-mobile': isMobile, 'language-selector--container-wf': theme === 'pwfglobal' || theme === 'vivae' || theme === 'acate' || theme === 'decolaentregadores', 'language-selector--container': theme !== 'pwfglobal' && theme !== 'vivae'}"
    :items="languages"
    outlined
    :menu-props="{'content-class': this.color, 'attach': true}"
    :width="isMobile ? '82px' : '200px'"
    full-width
    dense
    @change="changeLanguage"
  >
  <template v-slot:item="data">
    <div class="lato font-16">
      {{data.item.text}}
    </div>
  </template>
  <template v-slot:selection="data">
    <div v-if="!isMobile" :class="theme === 'pwfglobal' || theme === 'vivae' || theme === 'acate' || theme === 'decolaentregadores' ? 'language-selector--selected-wf': 'language-selector--selected'">
      {{data.item.text}}
    </div>
  </template>
    <template v-slot:prepend-inner>
      <v-img v-if="theme !== 'pwfglobal' && theme !== 'vivae' && theme !== 'acate' && theme !== 'decolaentregadores'" class="language-img" src="/assets/images/global.png" />
      <v-img v-else class="language-img" src="/assets/images/global-black.png" />
    </template>
  </v-select>
</template>
<style lang="scss">
.language-selector--container {
  max-width: 280px;
  &.is-mobile {
    max-width: 82px;
  }
  fieldset {
    border: none;
  }
  .v-input__slot {
    border: 2px solid $neutral-white;
  }

  .v-input__slot_wf {
    border: 2px solid $neutral-white;
  }

  .v-icon {
    margin-top: -4px;
    color: $neutral-white;
  }

  .v-icon-wf {
    margin-top: -4px;
    color: #212121;
  }
}

.language-selector--container-wf {
  max-width: 280px;
  &.is-mobile {
    max-width: 82px;
  }
  fieldset {
    border: none;
  }

  .v-input__slot {
    border: 2px solid #212121;
  }

  .v-icon {
    margin-top: -4px;
    color: #212121;
  }
}
.v-application .primary--text {
    color: $primary-light !important;
  }

.language-selector--selected {
  color: $neutral-white;
  font-weight: 700;
  // width: 300px;
  margin-left: 5px;
  text-transform: uppercase;
}
.language-selector--selected-wf {
  color: #212121;
  font-weight: 700;
  // width: 300px;
  margin-left: 5px;
  text-transform: uppercase;
}

.language-img {
  margin-top: -2px;
}
</style>
